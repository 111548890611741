import React from 'react'

export const SketchIcon = () => {
  return (
    <svg width="40" height="36" xmlns="http://www.w3.org/2000/svg">
      <title>Sketch</title>
      <g fill="none" fillRule="evenodd">
        <g id="sketch">
          <path
            id="Path"
            fill="#B6B6B6"
            d="M8.71 1.18L19.96 0l11.25 1.18 8.71 11.598-19.96 23.055L0 12.778z"
          />
          <path
            d="M8.085 12.777L19.96 35.834 0 12.778m31.835 0L19.96 35.832l19.96-23.055"
            fill="#7F7F7F"
            fillRule="nonzero"
          />
          <path
            id="Path"
            fill="#B2B2B2"
            d="M8.085 12.777h23.75L19.96 35.834"
          />
          <path
            d="M19.96 0L8.71 1.18l-.625 11.598M19.96 0l11.25 1.18.625 11.598"
            fill="#D0D0D0"
            fillRule="nonzero"
          />
          <path
            d="M39.92 12.777L31.21 1.18l.625 11.598M0 12.777L8.71 1.18l-.625 11.598"
            fill="#B2B2B2"
            fillRule="nonzero"
          />
          <path id="Path" fill="#EDEDED" d="M19.96 0L8.085 12.777h23.75" />
        </g>
      </g>
    </svg>
  )
}
