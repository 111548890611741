import React from 'react'
import { Link } from 'gatsby'

function Testimonial(props) {
  return (
    <section className="testimonial">
      <div className="container">
        <h2
          className="testimonial__heading 
                      text--3
                      underline"
        >
          Testimonial
        </h2>

        {props.projectTitle ? (
          <h3
            className="testimonial__project-title 
                          text--6"
          >
            {props.projectTitle}
          </h3>
        ) : null}

        <p className="testimonial__paragraph text--4">"{props.paragraph}"</p>
        <p
          className="testimonial__author
                      text--5
                      underline"
        >
          {props.author}
        </p>
        <p
          className="testimonial__role
                      text--6"
        >
          {props.authorRole}
        </p>

        {props.link ? (
          <Link
            to={props.link}
            className="testimonial__cta
                          btn
                          btn--secondary
                          btn--blue"
          >
            View Project
          </Link>
        ) : null}
      </div>
    </section>
  )
}

export default Testimonial
