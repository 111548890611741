import React from 'react'

export const CssIcon = () => {
  return (
    <svg width="40" height="47" xmlns="http://www.w3.org/2000/svg">
      <title>CSS 3</title>
      <g fill="none" fillRule="evenodd">
        <g id="css3-alt-brands" fill="currentColor" fillRule="nonzero">
          <path d="M0 0l3.635 41.23L20 46.666l16.365-5.438L40 0H0zm32.615 8.333l-.5 4.927-12.01 5.136-.032.01h11.614l-1.333 15.271-10.229 2.99-10.292-3.042-.666-7.698h5.093l.334 3.99 5.479 1.385 5.698-1.604.385-6.417-17.323-.052v-.01l-.02.01-.376-4.823 11.678-4.864.677-.282H7.99l-.605-4.927h25.23z" />
        </g>
      </g>
    </svg>
  )
}
