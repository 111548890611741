import React from 'react'

export const JavascriptIcon = () => {
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <title>JavaScript</title>
      <g fill="none" fillRule="evenodd">
        <g id="js-brands" fill="currentColor" fillRule="nonzero">
          <path d="M0 0v40h40V0H0zm21.768 31.196c0 3.893-2.286 5.67-5.616 5.67-3.01 0-4.75-1.553-5.643-3.437l3.062-1.849c.59 1.045 1.125 1.929 2.42 1.929 1.232 0 2.018-.482 2.018-2.366V18.366h3.759v12.83zm8.893 5.67c-3.491 0-5.75-1.66-6.849-3.84l3.063-1.767c.804 1.312 1.857 2.286 3.705 2.286 1.554 0 2.554-.777 2.554-1.858 0-1.285-1.018-1.74-2.741-2.5l-.938-.401c-2.714-1.152-4.509-2.607-4.509-5.67 0-2.821 2.152-4.964 5.5-4.964 2.393 0 4.108.83 5.34 3.009l-2.929 1.875c-.643-1.152-1.34-1.607-2.42-1.607-1.098 0-1.794.696-1.794 1.607 0 1.125.696 1.58 2.312 2.285l.938.402c3.196 1.366 4.99 2.768 4.99 5.91 0 3.376-2.66 5.233-6.222 5.233z" />
        </g>
      </g>
    </svg>
  )
}
